<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container thin short">
        <div class="modal-header">
          <div class="head">
            <div class="start">
              <div class="breadcrumb">
                <p>staff position</p>
                <span class="slash">/</span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-body">
          <div class="line">
            <div class="name">
              <span class="vertical-center">
                {{ staff.firstName }} <b>{{ staff.lastName }}</b>
              </span>
            </div>
          </div>
          <hr />
          <div class="position-grid-outer">
            <div class="position-grid">
              <div
                v-for="p in nonPlayerRoles"
                :key="p.type"
                class="position"
                @click="selectPosition(p)"
                :class="{
                  selected: role === p.type,
                  disabled: checkIfUnavailable(p),
                }"
              >
                {{ p.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <hr />
          <div class="buttons">
            <button
              class="loose"
              @click="submit"
              :disabled="!staffPositionValid"
            >
              ok
            </button>
            <button class="loose transparent" @click="$emit('close')">
              cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { nonPlayerRoles } from '@/utils/constants';
export default {
  name: 'StaffSelectPositionModal',
  props: ['staff', 'validate', 'staffPositionValid', 'currentStaffList'],
  methods: {
    submit() {
      this.$emit('submit', {
        _id: this.staff._id,
        role: this.role,
      });
    },
    selectPosition(p) {
      if (!this.checkIfUnavailable(p)) this.role = p.type;
    },
    checkIfUnavailable(role) {
      let unavailable = false;
      if (
        !(
          (role.memberType === 'coach' && this.staff.roles.includes('coach')) ||
          (role.memberType === 'trainer' &&
            this.staff.roles.includes('trainer')) ||
          (role.memberType === 'volunteer' &&
            this.staff.roles.includes('volunteer'))
        )
      ) {
        unavailable = true;
      }

      if (this.filledRoles.find(r => r === role.type)) unavailable = true;
      return unavailable;
    },
  },
  data() {
    return {
      role: '',
      nonPlayerRoles,
    };
  },
  computed: {
    filledRoles() {
      return this.currentStaffList.length > 0
        ? this.currentStaffList.map(s => s.role)
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.position-grid-outer {
  display: flex;
  justify-content: center;
}
.position-grid {
  display: grid;
  /*grid-template-columns: repeat(3, 32%);*/
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1.6%;
  grid-row-gap: 15px;

  width: 100%;
  @media (max-width: $xs) {
    width: 95%;
  }

  .position {
    cursor: pointer;
    padding: 5px 0;
    border: 2px solid grey;
    text-transform: uppercase;
    letter-spacing: 3px;
    &.selected {
      background-color: $primary;
      color: white;
      border-color: $primary;
    }
    &.disabled {
      cursor: auto;
      background-color: gray;
      color: white;
      border-color: gray;
    }
  }
}

.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}
.big-input {
  width: 100px;
  height: 40px;
  font-size: 1.5em;
  border: 2px solid black;
  margin-left: 10px;
  text-align: center;
  font-weight: bold;
  color: black;
}
.cap {
  font-size: 1.2em;
  color: grey;
}
button {
  width: 130px;
}

.line {
  display: flex;
  justify-content: space-between;
}
.name {
  font-size: 1.5em;
  display: flex;
}
.head {
  height: 50px;
  display: flex;
  margin-bottom: 10px;

  > * {
    margin-top: auto;
    margin-bottom: auto;
  }

  .start {
    flex-grow: 1;
    text-align: left;
  }
}
</style>
