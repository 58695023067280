<template>
  <div class="top">
    <div class="label-wrap">
      <div class="label-wrap-wrap">
        <span class="label">{{ label }}</span>
      </div>
    </div>
    <input id="cbx" type="checkbox" :checked="value" @change="inputEvent" />
    <label class="cbx" for="cbx">
      <div class="flip">
        <div class="front" />
        <div class="back">
          <svg width="16" height="14" viewBox="0 0 16 14">
            <path d="M2 8.5L6 12.5L14 1.5" />
          </svg>
        </div>
      </div>
    </label>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: ['value', 'label'],
  methods: {
    inputEvent(event) {
      this.$emit('input', event.target.checked);
    },
  },
};
</script>

<style lang="scss" scoped>
$scale: 1.5;
.top {
  height: 30px;
  margin-top: auto;
  margin-bottom: auto;
}
.label-wrap {
  vertical-align: center;
  display: inline-block;
  height: 100%;
}
.label-wrap-wrap {
  height: 100%;
  flex-direction: column;
  display: flex;
  justify-content: center;
}
.label {
  vertical-align: center;
  margin-right: 10px;
  font-size: 1.3em;
}
// custom checkbox
.cbx {
  perspective: 20px * $scale;
  position: absolute;
  border: 2px solid grey;
  border-radius: 4px;
  transform: translate3d(0, 0, 0);
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    border-color: $primary;
  }
}

.flip {
  display: block;
  transition: all 0.4s ease;
  transform-style: preserve-3d;
  position: relative;
  width: 20px * $scale;
  height: 20px * $scale;
}

#cbx {
  display: none;

  &:checked + .cbx {
    border-color: $primary;

    .flip {
      transform: rotateY(180deg);
    }
  }
}

.front,
.back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px * $scale;
  height: 20px * $scale;
  border-radius: 2px;
}

.front {
  background: #fff;
  z-index: 1;
}

.back {
  transform: rotateY(180deg);
  background: $primary;
  text-align: center;
  color: #fff;
  line-height: 20px * $scale;
  box-shadow: 0 0 0 1px $primary;

  svg {
    margin-top: 3px;
    fill: none;

    path {
      stroke: #fff;
      stroke-width: 2.5;
      stroke-linecap: round;
      stroke-linejoin: round;
    }
  }
}
</style>
