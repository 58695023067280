<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-container">
        <div class="modal-header">
          <div class="head">
            <div class="start">
              <div class="breadcrumb">
                <p>submit teamlist</p>
                <span class="slash">/</span>
              </div>
            </div>
          </div>
        </div>

        <div class="modal-body">
          <div class="line">
            <div class="name">
              <span class="vertical-center">
                {{
                  player.profile ? player.profile.firstName : player.firstName
                }}
                <b>{{
                  player.profile ? player.profile.lastName : player.lastName
                }}</b>
              </span>
            </div>
            <Checkbox v-model="isCaptain" label="Make Captain?" />
            <div class="shirt-no" :class="{ invalid: !shirtNumValid }">
              <span>Shirt No.</span>
              <input class="big-input" v-model="shirtNum" />
            </div>
          </div>
          <hr />
          <div class="position-grid-outer">
            <div class="position-grid">
              <div
                v-for="(p, i) in positions"
                :key="i"
                class="position"
                @click="selectPosition(p)"
                :class="{
                  selected: position && p.number === position.number,
                  disabled: filledPositionNumbers.includes(p.number),
                }"
              >
                {{ p.name }}
              </div>
            </div>
          </div>
        </div>

        <div class="modal-footer">
          <hr />
          <div class="buttons">
            <button
              class="loose"
              @click="submit"
              :disabled="!shirtNumValid || !positionValid"
            >
              ok
            </button>
            <button class="loose transparent" @click="$emit('close')">
              cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Checkbox from "@/components/input/Checkbox";
export default {
  name: "SelectPositionModal",
  components: { Checkbox },
  props: [
    "player",
    "validate",
    "shirtNumValid",
    "positionValid",
    "positions",
    "currentTeamList",
  ],
  mounted() {
    // populate initial data back from finished data
    const { shirtNum, isCaptain, position } = this.player;
    if (shirtNum) this.shirtNum = shirtNum;
    if (isCaptain) this.isCaptain = isCaptain;
    if (position) this.position = position;
    this.localValidate();
  },
  methods: {
    selectPosition(p) {
      if (!this.filledPositionNumbers.includes(p.number)) {
        this.position = p;
        // If the player hasn't been submitted on the TL before then shirtNum = default
        // otherwise keep the shirt number from the latest team list
        const replacementplayernum = p.number === 99 ? "18" : null;
        if (!this.shirtNum)
          this.shirtNum = replacementplayernum || `${p.number}`;
      }
    },
    localValidate() {
      const {
        shirtNum,
        isCaptain,
        position,
        player: { _id },
      } = this;
      this.validate({ shirtNum, _id, isCaptain, position });
    },
    submit() {
      const {
        shirtNum,
        position,
        isCaptain,
        player: { _id },
      } = this;
      this.$emit("submit", {
        shirtNum,
        position,
        isCaptain,
        _id,
      });
    },
  },
  data() {
    return {
      isCaptain: false,
      shirtNum: "",
      position: "",
    };
  },
  computed: {
    filledPositionNumbers() {
      return this.currentTeamList.length > 0
        ? this.currentTeamList
            .map((p) => p.position.number)
            .filter((n) => n !== this.position.number)
        : [];
    },
  },
  watch: {
    shirtNum() {
      this.localValidate();
    },
  },
};
</script>

<style lang="scss" scoped>
.shirt-no {
  font-size: 1.3em;
  &.invalid {
    color: red;
  }
}

.position-grid-outer {
  display: flex;
  justify-content: center;
}
.position-grid {
  display: grid;
  /*grid-template-columns: repeat(3, 32%);*/
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-column-gap: 1.6%;
  grid-row-gap: 15px;

  width: 100%;
  @media (max-width: $xs) {
    width: 95%;
  }

  .position {
    cursor: pointer;
    padding: 5px 0;
    border: 2px solid grey;
    text-transform: uppercase;
    letter-spacing: 3px;
    &.selected {
      background-color: $primary;
      color: white;
      border-color: $primary;
    }
    &.disabled {
      cursor: auto;
      background-color: gray;
      color: white;
      border-color: gray;
    }
  }
}

.vertical-center {
  margin-top: auto;
  margin-bottom: auto;
}
.big-input {
  width: 100px;
  height: 40px;
  font-size: 1.5em;
  border: 2px solid black;
  margin-left: 10px;
  text-align: center;
  font-weight: bold;
  color: black;
}
.cap {
  font-size: 1.2em;
  color: grey;
}
button {
  width: 130px;
}

.line {
  display: flex;
  justify-content: space-between;
}
.name {
  font-size: 1.5em;
  display: flex;
}
.head {
  height: 50px;
  display: flex;
  margin-bottom: 10px;

  > * {
    margin-top: auto;
    margin-bottom: auto;
  }

  .start {
    flex-grow: 1;
    text-align: left;
  }
}
</style>
