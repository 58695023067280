<template>
  <Default @submit="showSubmitModal = true" :key="testKey">
    <!--  MODALS -->
    <template slot="foot">
      <div class="footer-wrap">
        <div class="buttons flex-1">
          <button class="button b1 loose w-b" @click="$router.push('/landing')">
            Dashboard
          </button>
        </div>
      </div>
    </template>
    <SelectPositionModal
      v-if="showSelectPositionModal"
      :player="selectedPlayer"
      @close="showSelectPositionModal = false"
      @submit="handleSelectPosition"
      :validate="validateShirtPositionAndCaptain"
      :shirtNumValid="shirtNumValid"
      :positionValid="positionValid"
      :positions="positions"
      :currentTeamList="currentTeamList"
    />
    <StaffSelectPositionModal
      v-if="showStaffSelectPositionModal"
      :staff="selectedStaff"
      @close="showStaffSelectPositionModal = false"
      @submit="handleSelectStaff"
      :validate="validateStaff"
      :staffPositionValid="staffPositionValid"
      :currentStaffList="currentStaffList"
    />

    <SubmitModal
      v-if="showSubmitModal"
      :submitValid="submitValid"
      @submit="preSubmitTeamList"
      @close="closeSubmit"
    >
      <form
        style="display:hidden"
        id="submitTeam"
        @submit.prevent="submitTeamList()"
      ></form>
      <h3>Team List Type</h3>
      <div class="status-buttons">
        <div
          v-for="(s, i) in teamListStatusCoach"
          :key="i"
          class="position"
          @click="listStatus = s.type"
          :class="{
            selected: listStatus === s.type,
          }"
        >
          {{ s.name }}
        </div>
      </div>
    </SubmitModal>
    <Match
      v-if="currentMatch"
      :place="currentMatch.venue ? currentMatch.venue.name : ''"
      :team1="currentMatch.homeTeam ? currentMatch.homeTeam.name : ''"
      :team2="currentMatch.awayTeam ? currentMatch.awayTeam.name : ''"
      :club1="currentMatch.homeTeam ? currentMatch.homeTeam.orgtree.club : {}"
      :club2="currentMatch.awayTeam ? currentMatch.awayTeam.orgtree.club : {}"
      :score1="currentMatch.scores ? currentMatch.scores.homeTeam : undefined"
      :score2="currentMatch.scores ? currentMatch.scores.awayTeam : undefined"
      :time="matchTime(currentMatch.dateTime)"
      :title="
        `Round ${currentMatch.round.number} ${matchDate(currentMatch.dateTime)}`
      "
    />
    <div class="columns">
      <div class="column w-6">
        <div class="mini-header">
          Squad
        </div>
        <div v-if="currentPlayerSquad" class="grid mt-20">
          <div
            :class="
              p.available && p.available.is === false
                ? 'card name-tag disabled'
                : 'card name-tag clickable'
            "
            v-for="p in currentPlayerSquad"
            :key="p._id"
            @click="selectPosition(p._id, p.available)"
          >
            {{ p.profile.firstName }} <b>{{ p.profile.lastName }}</b>
          </div>
        </div>
      </div>
      <div class="column w-3">
        <div class="mini-header">
          Team Selection
        </div>
        <div v-if="currentStartingList && currentMatch">
          <div
            v-for="i in currentMatch.competitionSetting.positions"
            :key="i.order"
          >
            <div
              v-if="findStartingPlayer(i.order)"
              class="card name-tag d-flex mt-10 clickable"
              :class="{
                selected: findStartingPlayer(i.order).isCaptain === true,
              }"
              @click.stop="selectPosition(findStartingPlayer(i.order)._id)"
            >
              <div class="number-circle">
                <b>{{ findStartingPlayer(i.order).shirtNum }}</b>
              </div>
              <div class="profile-name flex-1">
                {{ findStartingPlayer(i.order).firstName }}
                <b>{{ findStartingPlayer(i.order).lastName }}</b>
                {{
                  findStartingPlayer(i.order).isCaptain === true ? "(C)" : ""
                }}
                <br />
                <span>{{ findStartingPlayer(i.order).position.name }}</span>
              </div>
              <div
                class="remove"
                @click.stop="removePlayer(findStartingPlayer(i.order)._id)"
              >
                X
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column w-3">
        <div>
          <div class="mini-header">
            interchange bench
          </div>
          <div v-if="currentInterchangeList && currentMatch">
            <div
              v-for="i in currentMatch.competitionSetting.positions"
              :key="i.order"
            >
              <div
                v-if="findInterchangePlayer(i.order)"
                class="card name-tag d-flex mt-10 clickable"
                @click.stop="selectPosition(findInterchangePlayer(i.order)._id)"
              >
                <div class="number-circle">
                  <b>{{ findInterchangePlayer(i.order).shirtNum }}</b>
                </div>
                <div class="profile-name flex-1">
                  {{ findInterchangePlayer(i.order).firstName }}
                  <b>{{ findInterchangePlayer(i.order).lastName }}</b>
                  <br />
                  <span>{{
                    findInterchangePlayer(i.order).position.name
                  }}</span>
                </div>
                <div
                  class="remove"
                  @click.stop="removePlayer(findInterchangePlayer(i.order)._id)"
                >
                  <span>
                    X
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="mini-header mt-20">
            18th Player Replacement
          </div>

          <div v-if="currentReplacememntPlayer && currentMatch">
            <div>
              <div
                class="card name-tag d-flex mt-10 clickable"
                @click.stop="selectPosition(currentReplacememntPlayer._id)"
              >
                <div class="number-circle">
                  <b>{{ currentReplacememntPlayer.shirtNum }}</b>
                </div>
                <div class="profile-name flex-1">
                  {{ currentReplacememntPlayer.firstName }}
                  <b>{{ currentReplacememntPlayer.lastName }}</b>
                  <br />
                  <span>{{ currentReplacememntPlayer.position.name }}</span>
                </div>
                <div
                  class="remove"
                  @click.stop="removePlayer(currentReplacememntPlayer._id)"
                >
                  <span>
                    X
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="mini-header mt-20">
            Team Staff
          </div>
          <div
            v-for="s in currentStaffList"
            :key="s._id"
            class="card name-tag d-flex mt-10"
          >
            <div class="profile-name flex-1">
              {{ s.firstName }} <b>{{ s.lastName }}</b>
              <br />
              <span>{{ getStaffRoleName(s.role) }}</span>
            </div>
            <div class="remove clickable" @click="removeStaff(s._id)">
              X
            </div>
          </div>
          <div class="mini-header mt-20">
            Unassigned Staff
          </div>
          <div
            v-for="s in currentStaffSquad"
            :key="s._id"
            :class="
              s.available && s.available.is === false
                ? 'card name-tag d-flex mt-10 disabled'
                : 'card name-tag d-flex mt-10 clickable'
            "
            @click="selectStaff(s._id, s.available)"
          >
            <div class="profile-name flex-1">
              {{ s.profile.firstName }} <b>{{ s.profile.lastName }}</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Default>
</template>

<script>
import Default from "@/layouts/default";
import SelectPositionModal from "@/components/modals/SelectPositionModal";
import StaffSelectPositionModal from "@/components/modals/StaffSelectPositionModal";
import SubmitModal from "@/components/modals/SubmitModal";
import Match from "@/components/MatchCard";
import moment from "moment";
import { teamListStatusCoach, nonPlayerRoles } from "@/utils/constants";

export default {
  name: "teamList",
  components: {
    StaffSelectPositionModal,
    Match,
    SelectPositionModal,
    Default,
    SubmitModal,
  },
  data() {
    return {
      currentMatch: undefined,
      testKey: 998,
      focusedPlayerId: "",
      focusedStaffId: "",
      Default,
      teamList: [],
      showSelectPositionModal: false,
      showStaffSelectPositionModal: false,
      shirtNumValid: true,
      positionValid: true,
      staffPositionValid: true,
      showSubmitModal: false,
      listStatus: "",
      teamListStatusCoach,
    };
  },
  methods: {
    closeSubmit() {
      this.showSubmitModal = false;
      this.$notify.closeAll();
    },
    findStartingPlayer(order) {
      return (
        this.currentStartingList.find((p) => p.position.order === order) ||
        false
      );
    },
    findInterchangePlayer(order) {
      return (
        this.currentInterchangeList.find((p) => p.position.order === order) ||
        false
      );
    },
    findReplacementPlayer(order) {
      if (
        this.currentReplacememntPlayer &&
        this.currentReplacememntPlayer[0] &&
        this.currentReplacememntPlayer[0].position.order === order
      ) {
        return this.currentReplacememntPlayer[0];
      } else {
        return false;
      }
      // return (
      //   this.currentReplacememntPlayer.find(
      //     (p) => p.position.order === order
      //   ) || false
      // );
    },
    validateShirtPositionAndCaptain({ shirtNum, _id, position, isCaptain }) {
      this.validateShirt({ shirtNum, _id, position, isCaptain });
      if (position) this.positionValid = true;
    },
    validateStaff({ role }) {
      if (role) this.staffPositionValid = true;
    },
    validateShirt({ shirtNum, _id }) {
      if (shirtNum.length > 2 || !shirtNum) return (this.shirtNumValid = false);
      if (
        this.currentTeamList.length > 0 &&
        this.currentTeamList
          .filter((p) => p._id !== _id)
          .map((p) => p.shirtNum)
          .includes(shirtNum)
      ) {
        return (this.shirtNumValid = false);
      }
      return (this.shirtNumValid = true);
    },
    // eslint-disable-next-line no-unused-vars
    handleSelectPosition({ shirtNum, position, isCaptain, _id }) {
      const squadPlayer =
        this.teamList &&
        this.teamList.player &&
        this.teamList.player.currentSquad.find(
          (p) => this.focusedPlayerId === p._id,
        );

      if (squadPlayer) {
        const newPlayer = {
          _id: squadPlayer._id,
          isPrivate: squadPlayer.profile.isPrivate,
          firstName: squadPlayer.profile.firstName,
          middleName: squadPlayer.profile.middleName || "",
          lastName: squadPlayer.profile.lastName,
          preferredName:
            squadPlayer.profile.meta && squadPlayer.profile.meta.preferredName
              ? squadPlayer.profile.meta.preferredName
              : "",
          shirtNum,
          isCaptain,
          position,
        };

        this.teamList.player.teamList =
          this.teamList.player.teamList.length > 0
            ? this.teamList.player.teamList
                .filter((p) => p._id !== newPlayer._id)
                .concat([newPlayer])
            : [newPlayer];
      }

      this.showSelectPositionModal = false;
      this.$forceUpdate();
    },
    handleSelectStaff({ _id, role }) {
      const squadStaff =
        this.currentStaffSquad &&
        this.currentStaffSquad.length > 0 &&
        this.currentStaffSquad.find((p) => _id === p._id);

      if (squadStaff) {
        const newStaff = {
          _id: squadStaff._id,
          isPrivate: squadStaff.profile.isPrivate,
          firstName: squadStaff.profile.firstName,
          middleName: squadStaff.profile.middleName || "",
          lastName: squadStaff.profile.lastName,
          preferredName: squadStaff.profile.meta.preferredName,
          role,
        };

        this.teamList.nonPlayer.teamList =
          this.teamList.nonPlayer.teamList.length > 0
            ? this.teamList.nonPlayer.teamList.concat([newStaff])
            : [newStaff];
      }

      this.showStaffSelectPositionModal = false;
    },
    removePlayer(_id) {
      this.teamList.player.teamList = this.teamList.player.teamList.filter(
        (p) => p._id !== _id,
      );
    },
    removeStaff(_id) {
      this.teamList.nonPlayer.teamList = this.teamList.nonPlayer.teamList.filter(
        (p) => p._id !== _id,
      );
    },
    selectPosition(id, available = false) {
      if (available && available.is === false) {
        this.$notify({
          type: "warning",
          title: "Player Unavailable",
          message:
            available.notes && available.notes !== ""
              ? `This player is unavailale due to ${available.notes}`
              : "This player is unavailable",
        });
        return;
      }
      this.focusedPlayerId = id;
      this.showSelectPositionModal = true;
    },
    selectStaff(id, available = false) {
      if (available && available.is === false) {
        this.$notify({
          type: "warning",
          title: "Staff Unavailable",
          message:
            available.notes && available.notes !== ""
              ? `This staff is unavailale due to ${available.notes}`
              : "This staff is unavailable",
        });
        return;
      }
      this.focusedStaffId = id;
      this.showStaffSelectPositionModal = true;
    },
    matchTime(dateTime) {
      return moment(dateTime).format("h:mm a");
    },
    matchDate(dateTime) {
      return moment(dateTime).format("dddd Do MMMM");
    },
    convertArrayToObject(array, key) {
      array.reduce((acc, curr) => {
        acc[curr[key]] = curr;
        return acc;
      }, {});
    },

    preSubmitTeamList() {
      const checkedPositions = [];
      for (const position of this.positions) {
        checkedPositions.push(position);
      }

      for (const player of this.teamList.player.teamList) {
        // check off each position
        let i = 0;

        for (const position of checkedPositions) {
          if (player.position.abbreviation === position.abbreviation) {
            // remove from the checklist
            checkedPositions.splice(i, 1);
            // exit the loop and go onto next player
            break;
          }
          i++;
        }
      }
      // now if any positions remaining let them know
      let positionsMissing = "";
      for (const pos of checkedPositions) {
        if (pos.name && pos.abbreviation !== "R")
          positionsMissing = `${pos.name}, ${positionsMissing}`;
      }
      if (positionsMissing !== "") {
        this.$notify({
          type: "warning",
          title: "Warning",
          duration: 0,
          dangerouslyUseHTMLString: true,
          message: `<p>You have missing positions<br>${positionsMissing}<br> are you sure you want to submit?<br>Note: This warning may not apply if your competition does not require a replacement player.</p> <br> <button form="submitTeam" type="submit" class="transparent" value="">yes</button>`,
        });
      } else {
        this.submitTeamList();
      }
    },
    submitTeamList() {
      if (this.$notify) this.$notify.closeAll();
      if (!this.validateTeam()) return;
      this.showSubmitModal = false;
      this.$store.commit("LOADING", true);
      this.$http
        .post("/nrl/api/v1/elite/teamlists", {
          matchId: this.currentMatch._id,
          teamId: this.currentUserTeamId,
          status: this.listStatus,
          players: this.currentTeamList.map((p) => ({
            _id: p._id,
            shirtNum: p.shirtNum,
            firstName: p.firstName,
            middleName: p.middleName || "",
            lastName: p.lastName,
            preferredName: p.preferredName,
            isPrivate: p.isPrivate || false,
            isCaptain: p.isCaptain,
            position: p.position,
          })),
          nonPlayers: this.currentStaffList.map((s) => ({
            _id: s._id,
            firstName: s.firstName,
            middleName: s.middleName || "",
            lastName: s.lastName,
            preferredName: s.preferredName,
            isPrivate: s.isPrivate || false,
            role: s.role,
          })),
        })
        .then((response) => {
          if (response) {
            this.$http
              .get(
                `/nrl/api/v1/elite/teamlists/match/${this.currentMatch._id}/team/${this.currentUserTeamId}`,
              )
              .then((response) => {
                if (response) {
                  this.teamList = response.data.data;
                  this.$notify({
                    title: "Success",
                    message: "Team List Submitted",
                  });
                }
                this.$store.commit("LOADING", false);
              })
              .catch((e) => {
                this.$notify.error({
                  title: "Error",
                  message:
                    e.response && e.response.data && e.response.data.message
                      ? e.response.data.message
                      : "There has been an error",
                });
                this.$store.commit("LOADING", false);
              });
          }
        })
        .catch((e) => {
          this.$notify.error({
            title: "Error",
            message:
              e.response && e.response.data && e.response.data.message
                ? e.response.data.message
                : "There has been an error",
          });
          this.$store.commit("LOADING", false);
        });
    },
    validateTeam() {
      // if (!this.currentStartingList.find(p => p.isCaptain === true)) {
      //   this.$notify.error({
      //     title: 'Error',
      //     message: 'Team Captain is required',
      //   });
      //   return false;
      // }

      // if (this.currentStartingList.length !== 13) {
      //   this.$notify.error({
      //     title: 'Error',
      //     message: '13 Starting Players required',
      //   });
      //   return false;
      // }

      // if (
      //   this.currentInterchangeList.filter(
      //     p => p.position.name.toLowerCase() === 'interchange',
      //   ).length !== 4
      // ) {
      //   this.$notify.error({
      //     title: 'Error',
      //     message: '4 Interchange Players required',
      //   });
      //   return false;
      // }

      // const numReserve = {
      //   'mid-week': 4,
      //   '24h': 2,
      //   '1h': 0,
      // };

      // if (
      //   this.currentInterchangeList.filter(
      //     p => p.position.name.toLowerCase() === 'reserve',
      //   ).length !== numReserve[this.listStatus]
      // ) {
      //   this.$notify.error({
      //     title: 'Error',
      //     message: `${numReserve[this.listStatus]} Reserve Players required`,
      //   });
      //   return false;
      // }

      // if (
      //   !this.currentStaffList.find(s => s.role === 'coach') &&
      //   this.listStatus === '1h'
      // ) {
      //   this.$notify.error({
      //     title: 'Error',
      //     message: 'Coach is required',
      //   });
      //   return false;
      // }
      // if (
      //   this.currentStaffList.filter(s => s.role.includes('trainer')).length !==
      //     3 &&
      //   this.listStatus === '1h'
      // ) {
      //   this.$notify.error({
      //     title: 'Error',
      //     message: '3 Trainers are required',
      //   });
      //   return false;
      // }
      return true;
    },
    getStaffRoleName(role) {
      return nonPlayerRoles.find((r) => r.type === role) &&
        nonPlayerRoles.find((r) => r.type === role).name
        ? nonPlayerRoles.find((r) => r.type === role).name
        : "Role Not Applicable";
    },
  },
  computed: {
    selectedPlayer() {
      if (this.teamList && this.teamList.player) {
        return (
          (this.teamList.player.teamList &&
            this.teamList.player.teamList.find(
              (p) => this.focusedPlayerId === p._id,
            )) ||
          this.teamList.player.currentSquad.find(
            (p) => this.focusedPlayerId === p._id,
          )
        );
      }
      return undefined;
    },
    currentUserTeamId() {
      // return this.$store.state.user.profile.meta.team._id;
      const teamId = String(this.$store.state.user.activeRole._id).split(
        `${this.$store.state.user.activeRole.type}`,
      )[1];
      return parseInt(teamId);
    },
    currentPlayerSquad() {
      const currentTeamListIds =
        this.teamList &&
        this.teamList.player &&
        this.teamList.player.teamList.length > 0
          ? this.teamList.player.teamList.map((p) => p._id)
          : [];

      if (this.teamList && this.teamList.player) {
        return this.teamList.player.currentSquad
          .filter((p) => !currentTeamListIds.includes(p._id))
          .sort((a, b) => {
            if (
              a.profile.firstName.toLowerCase() >
              b.profile.firstName.toLowerCase()
            ) {
              return 1;
            }
            if (
              a.profile.firstName.toLowerCase() <
              b.profile.firstName.toLowerCase()
            ) {
              return -1;
            }
            if (
              a.profile.firstName.toLowerCase() ==
              b.profile.firstName.toLowerCase()
            ) {
              if (
                a.profile.lastName.toLowerCase() >
                b.profile.lastName.toLowerCase()
              ) {
                return 1;
              }
              if (
                a.profile.lastName.toLowerCase() <
                b.profile.lastName.toLowerCase()
              ) {
                return -1;
              }
            }
            return 0;
          });
      }
      return [];
    },
    currentTeamList() {
      if (
        this.teamList &&
        this.teamList.player &&
        this.teamList.player.teamList.length > 0
      ) {
        return this.teamList.player.teamList;
      }
      return [];
    },
    currentStartingList() {
      if (
        this.teamList &&
        this.teamList.player &&
        this.teamList.player.teamList.length > 0
      ) {
        return this.teamList.player.teamList.filter(
          (p) =>
            p.position.abbreviation !== "INT" &&
            p.position.abbreviation !== "R" &&
            p.position.abbreviation !== "18M",
        );
      }
      return [];
    },
    currentInterchangeList() {
      if (
        this.teamList &&
        this.teamList.player &&
        this.teamList.player.teamList.length > 0
      ) {
        return this.teamList.player.teamList.filter(
          (p) =>
            p.position.abbreviation === "INT" ||
            p.position.abbreviation === "R",
        );
      }
      return [];
    },
    currentReplacememntPlayer() {
      if (
        this.teamList &&
        this.teamList.player &&
        this.teamList.player.teamList.length > 0
      ) {
        const [replacement] = this.teamList.player.teamList.filter(
          (p) => p.position.abbreviation === "18M",
        );
        return replacement;
      }
      return null;
    },
    selectedStaff() {
      return (
        this.currentStaffSquad &&
        this.currentStaffSquad.length > 0 &&
        this.focusedStaffId &&
        this.currentStaffSquad.find((p) => this.focusedStaffId === p._id)
      );
    },
    currentStaffSquad() {
      const currentStaffListIds =
        this.teamList &&
        this.teamList.nonPlayer &&
        this.teamList.nonPlayer.teamList.length > 0
          ? this.teamList.nonPlayer.teamList.map((s) => s._id)
          : [];

      if (
        this.teamList &&
        this.teamList.nonPlayer &&
        this.teamList.nonPlayer.currentSquad.length > 0
      ) {
        return this.teamList.nonPlayer.currentSquad.filter(
          (s) => !currentStaffListIds.includes(s._id),
        );
      }
      return [];
    },
    currentStaffList() {
      if (
        this.teamList &&
        this.teamList.nonPlayer &&
        this.teamList.nonPlayer.teamList.length > 0
      ) {
        return this.teamList.nonPlayer.teamList;
      }
      return [];
    },
    positions() {
      return this.currentMatch.competitionSetting.positions;
    },
    submitValid() {
      return teamListStatusCoach.map((t) => t.type).includes(this.listStatus);
    },
  },
  mounted() {
    this.$store.commit("LOADING", true);
    this.$http
      .get("/nrl/api/v1/elite/matches-team")
      .then((response) => {
        if (response) {
          this.currentMatch = response.data.data;
          if (!this.currentMatch || !this.currentMatch._id)
            throw new Error("No upcoming match found.");
          this.$store.commit("match/CURRENT_MATCH", response.data.data);
          this.$http
            .get(
              `/nrl/api/v1/elite/teamlists/match/${this.currentMatch._id}/team/${this.currentUserTeamId}`,
            )
            .then((response) => {
              if (response) {
                this.teamList = response.data.data;
              }
              this.$store.commit("LOADING", false);
            })
            .catch((e) => {
              this.$notify.error({
                title: "Error",
                message:
                  e.response && e.response.data && e.response.data.message
                    ? e.response.data.message
                    : "There has been an error",
              });
              this.$store.commit("LOADING", false);
              this.$router.push("/landing");
            });
        }
      })
      .catch((e) => {
        console.log({ e });
        this.$notify.error({
          title: "Error",
          message:
            e.response && e.response.data && e.response.data.message
              ? e.response.data.message
              : e.message
              ? e.message
              : "There has been an error",
        });
        this.$store.commit("LOADING", false);
        this.$router.push("/landing");
      });
  },
};
</script>

<style lang="scss" scoped>
.green-square {
  background-color: #1aff23;
  padding: 0 6px;
}
.staff-category {
  padding: 0 5px;
  white-space: nowrap;
}

.name-tag {
  text-align: left;
  padding-left: 20px;

  .number-circle {
    margin-top: auto;
    margin-bottom: auto;
  }

  .profile-name {
    margin: auto;

    span {
      text-transform: uppercase;
      color: $primary;
      font-size: 0.75rem;
    }
  }

  .remove {
    margin: auto 10px;
    color: red;
  }
}

.grid {
  display: grid;
  grid-template-columns: 32% 32% 32%;
  grid-column-gap: 1.6%;
  grid-row-gap: 15px;
}

.status-buttons {
  div {
    margin-top: 10px;
    cursor: pointer;
    padding: 5px 0;
    border: 2px solid grey;
    text-transform: uppercase;
    letter-spacing: 3px;
    &.selected {
      background-color: $primary;
      color: white;
      border-color: $primary;
    }
  }
}

.disabled {
  background: lightgray;
  cursor: not-allowed;
}

.w-b {
  width: 200px;
}
.footer-wrap {
  padding: 7px 20px;
  display: flex;
}
</style>
